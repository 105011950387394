
import { useRouter } from 'vue-router';
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const workers = computed(() => store.getters.getWorkers);

    store.dispatch('fetchWorkers');
    const navigate = (id: number) => {
      router.push({ name: 'MitarbeiterEdit', params: { id } });
    };

    return {
      workers,
      navigate,
    };
  },
});
